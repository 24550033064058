import React, { memo } from 'react'
import { graphql, PageProps } from 'gatsby'

import {
  PatientStoryQuery,
  // @ts-ignore
} from '__generated__/gatsby-types'

import Story from './layouts/Story'

interface Props extends PageProps {
  data: PatientStoryQuery
}

const PatientStory = ({ data, location }: Props) => {
  const d = data.datoCmsPatientStory
  const other = data.allDatoCmsPatientStory.edges

  return (
    <Story
      meta={d.seoMetaTags}
      keyId={d.originalId}
      imageLandscape={d.imageLandscape.gatsbyImageData}
      imagePortrait={d.imagePortrait.gatsbyImageData}
      smallHeading="Patient stories"
      heading={d.headingNode.childMarkdownRemark.html}
      href={location.href}
      moreHeading={data.datoCmsGlobalContent.morePatientStoriesHeading}
      moreCards={other}
      contentComponents={d.contentComponents}
      modelName="story"
    />
  )
}

export default memo(PatientStory)

export const pageQuery = graphql`
  query PatientStoryQuery($originalId: String!) {
    datoCmsPatientStory(originalId: { eq: $originalId }) {
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headingNode {
        childMarkdownRemark {
          html
        }
      }
      contentComponents {
        ... on DatoCmsHeading {
          ...contentHeading
        }
        ... on DatoCmsSubHeading {
          ...contentSubHeading
        }
        ... on DatoCmsText {
          ...contentText
        }
        ... on DatoCmsImage {
          ...contentImage
        }
        ... on DatoCmsYoutubeVideo {
          ...contentYouTube
        }
        ... on DatoCmsButton {
          ...button
        }
        ... on DatoCmsDocument {
          ...contentDocument
        }
        ... on DatoCmsButtonGroup {
          ...contentButtonGroup
        }
        ... on DatoCmsLeadText {
          ...contentLeadText
        }
        ... on DatoCmsQuote {
          ...contentQuote
        }
        ... on DatoCmsSimpleQuote {
          ...contentSimpleQuote
        }
        ... on DatoCmsImageTextSection {
          ...contentImageTextSection
        }
        ... on DatoCmsImageLeadTextSection {
          ...contentImageLeadTextSection
        }
      }
      imageLandscape: heroImage {
        gatsbyImageData(
          width: 800
          layout: FULL_WIDTH
          imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint" }
        )
      }
      imagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "1:1.3", fit: "crop", crop: "focalpoint" }
        )
      }
    }
    allDatoCmsPatientStory(
      filter: { originalId: { ne: $originalId } }
      sort: { fields: position }
      limit: 6
    ) {
      edges {
        node {
          ...storyCard
        }
      }
    }
    datoCmsGlobalContent {
      morePatientStoriesHeading
    }
  }
`
