import React, { memo } from 'react'
import { Box, Container, Divider } from '@mui/material'
import { IGatsbyImageData } from 'gatsby-plugin-image'
meta: DatoCmsSeoMetaTags

import {
  DatoCmsSeoMetaTags,
  DatoCmsPatientStory,
  DatoCmsDonorCampaign,
  DatoCmsUnionForDatoCmsPatientStoryContentComponents,
  DatoCmsUnionForDatoCmsDonorCampaignContentComponents,
  // @ts-ignore
} from '__generated__/gatsby-types'

import Layout from 'components/organisms/global/Layout'
import StoryHero from 'components/organisms/hero/StoryHero'
import ContentComponents from 'components/molecules/contentComponents/ContentComponents'
import ShareSocialButtons from 'components/molecules/ShareSocialButtons'
import SwiperLandingSection from 'components/organisms/sections/SwiperLandingSection'

interface Props {
  keyId: string
  meta: DatoCmsSeoMetaTags
  smallHeading: string
  heading: string
  href: string
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  moreHeading?: string
  modelName: string
  moreCards?: [DatoCmsPatientStory | DatoCmsDonorCampaign]
  contentComponents: [
    | DatoCmsUnionForDatoCmsPatientStoryContentComponents
    | DatoCmsUnionForDatoCmsDonorCampaignContentComponents
  ]
}

const Story = ({
  keyId,
  smallHeading,
  heading,
  imageLandscape,
  imagePortrait,
  href,
  moreHeading,
  moreCards,
  contentComponents,
  meta,
  modelName,
}: Props) => {
  return (
    <Layout
      metadata={{
        seoTags: meta,
      }}
      forceSolidNav
    >
      <StoryHero
        contentBgColor="common.white"
        keyId={keyId}
        imageLandscape={imageLandscape}
        imagePortrait={imagePortrait}
        heading={heading}
        smallHeading={smallHeading}
      />
      <Box
        component="section"
        bgcolor="common.white"
        mt={{ xl: 6 }}
        pb={{ xs: 6, md: 8, lg: 10, xl: 13 }}
        position="relative"
      >
        <Container
          maxWidth="xl"
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: {
              xs: 'none',
              xl: 'block',
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box>
              <ShareSocialButtons
                url={href}
                keyId={keyId}
                variant="border-dark"
                modelName="event"
                mailSubject={`LCS Patient Story: `}
                verticalPanel
              />
            </Box>
          </Box>
        </Container>

        <ContentComponents
          components={contentComponents}
          containedWidth="narrow"
          wideImages
          boxProps={{ pb: { xs: 6 } }}
          disableTopSpacing
        />

        <Container
          maxWidth="md"
          sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}
        >
          <Divider />

          <ShareSocialButtons
            url={href}
            keyId={keyId}
            variant="border-dark"
            modelName={modelName}
            mailSubject={`LCS Patient Story: `}
          />
        </Container>
      </Box>
      {moreHeading && moreCards && (
        <SwiperLandingSection
          heading={moreHeading}
          cards={moreCards}
          dateLayout
          sectionBgColor="lightGrey"
          containerBgProps={{
            bgcolor: 'common.white',
          }}
        />
      )}
    </Layout>
  )
}

export default memo(Story)
