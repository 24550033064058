import React, { memo } from 'react'
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'

// @ts-ignore
import { DatoCmsButton } from '__generated__/gatsby-types'

import BackgroundPlx from 'components/atoms/BackgroundPlx'
import FixedHeightHeroContainer from 'components/atoms/FixedHeightHeroContainer'
import Sun from 'components/atoms/Sun'
import CurveMask from 'components/atoms/CurveMask'
import CornerBox from 'components/atoms/CornerBox'
import MainHeading from 'components/molecules/MainHeading'

export interface StoryHeroPropsBase {
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  imageAlt: string
  keyId: string
  smallHeading?: string
  heading: string
}

interface StoryHeroProps extends StoryHeroPropsBase {
  children: React.ReactNode
  contentBgColor: string
  altHeroBg?: boolean
}

// prettier-ignore
const StyledFixedHeightHeroContainer = styled(FixedHeightHeroContainer)(({ theme }) => ({
    background: theme.palette.common.white,
    position: 'relative',
}))
const StyledBackgroundPlx = styled(BackgroundPlx)(({ theme }) => ({
  background: theme.palette.secondary.main,
  ['&.alt-bg']: {
    background: theme.palette.primary.dark,
  },
}))

const StoryHero = ({
  keyId,
  imageLandscape,
  imagePortrait,
  imageAlt,
  smallHeading,
  heading,
  contentBgColor,
  altHeroBg,
}: StoryHeroProps) => {
  const theme = useTheme()

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <>
      <StyledFixedHeightHeroContainer>
        <Box
          className="fixed-height"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            // mb: { xs: 4, sm: 5, lg: 6 },
          }}
        >
          <StyledBackgroundPlx
            parallaxData={parallaxData}
            className={altHeroBg ? 'alt-bg' : ''}
          >
            <Sun size="large" color="light" position="bottom-left-xl" />
            <Sun size="xl" color="light" position="top-right-xl" />
          </StyledBackgroundPlx>
          <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <CurveMask bgColor={contentBgColor} overflow>
              <Box width="100%" height="100px" bgcolor="white" />
            </CurveMask>
          </Box>
          <Container
            maxWidth="lg"
            sx={{
              textAlign: 'center',
              color: 'white',
              pt: 18,
              position: 'relative',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} md={7}>
                {smallHeading && (
                  <Typography
                    variant="subHeading"
                    component="h2"
                    textAlign={{ xs: 'center', md: 'left' }}
                  >
                    {smallHeading}
                  </Typography>
                )}
                <MainHeading
                  text={heading}
                  keyId={`heading-${keyId}`}
                  breakpoint="xs"
                  variant="h1Small"
                  component="h1"
                  sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    pb: { xs: 4, md: 22, lg: 24, xl: 26 },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                lg={5}
                sx={{ alignSelf: 'flex-end', position: 'relative', zIndex: 10 }}
              >
                <CornerBox breakpointDown="xs">
                  <GatsbyImage
                    image={images}
                    alt={imageAlt ? imageAlt : ''}
                    imgStyle={{
                      borderRadius: theme.shape?.borderRadius,
                    }}
                  />
                </CornerBox>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </StyledFixedHeightHeroContainer>
    </>
  )
}

export default memo(StoryHero)
